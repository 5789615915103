import Axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import config from "../../../constants/apiList";
import dataResource from "../../../constants/dataResource";
import moment from "moment";

export default function StatusPopUp2({
  doctorId,
  id,
  form,
  setModalState,
  activityLog
}) {
  const [status, setStatus] = useState({ value: "", label: "" });
  const [remarks, setRemarks] = useState("");
  const [remarks2, setRemarks2] = useState({ name: "" });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4>Status</h4>
      <div>
        <div className="status-drop">
          Call Status
          <div
            className="categoryDropdown"
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Select
              options={dataResource.statusList}
              values={[status]}
              dropdownPosition="auto"
              labelField="label"
              valueField="value"
              addPlaceholder={status.value ? "" : "Set Status"}
              onChange={val => {
                if (!val[0] || !val[0].value || !val[0].label) return;

                setStatus({ value: val[0].value, label: val[0].label });
              }}
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
        </div>
        Enter Remarks
        <textarea
          cols={20}
          rows={2}
          placeholder=" Enter Remarks"
          type="text"
          required
          value={remarks}
          name="remarks"
          onChange={e => setRemarks(e.target.value)}
          className="sib--remark-ta"
          style={{ width: "92%", marginTop: "5px", resize: "none" }}
        />
        {/* <div className="remarksDropdown" style={{ marginTop: "5px" }}>
          <Select
            options={dataResource.remarksList.map(val => ({ name: val }))}
            values={[remarks2]}
            dropdownPosition="auto"
            labelField="name"
            valueField="name"
            addPlaceholder={remarks2.name ? "" : "Choose Remark"}
            onChange={val => {
              if (!val[0] || !val[0].name) return;

              setRemarks2({ name: val[0].name });
            }}
            style={{ width: "100%", padding: "5px" }}
          />
        </div> */}
        <div style={{ marginTop: "10px" }}>
          <Button
            className="btn btn-s"
            variant="primary"
            onClick={() => {
              Axios.post(config.url.UPDATE_STATUS_URL, {
                doctorId,
                form,
                id,
                status: status.value,
                remarks: remarks + (remarks2.name ? ", " + remarks2.name : "")
              })
                .then(res => {
                  console.log("idhar hi tha")
                  console.log(res);
                  const date = new Date();
                  const newDate = new Date(date.setMonth(date.getMonth() + 1));
                  const dateStr = moment()
                    .subtract(5.5, "hours")
                    .format("YYYY-MM-DD HH:mm:ss");
                  const newActivity = {
                    updated_dttm: dateStr,
                    remarks:
                      remarks + (remarks2.name ? ", " + remarks2.name : ""),
                    status: status.value
                  };
                  setModalState("activityLog", [newActivity, ...activityLog]);
                  setModalState("statusModal", false);
                  setModalState("status", {
                    value: status.value,
                    label: dataResource.statusListObj[status.value]
                  });
                  console.log("changing window")
                  window.open("/dashboard/appointments/summary", "_self");
                })
                .catch(err => {
                  alert("Status Update failed!");
                  setModalState("statusModal", false);
                  console.log(err);
                });
            }}
          >
            Update Status
          </Button>
        </div>
      </div>
    </div>
  );
}
