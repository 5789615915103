let api_domain = "https://api.mosaicwellness.in/prescription-generator/";
let scheduler_api_domain =
  //"https://api.mosaicwellness.in/appointment-scheduler/";
  "https://api.mosaicwellness.in/appointment-scheduler/";
let report_api_domain =
  //"https://api.mosaicwellness.in/appointment-scheduler/reports/";
  "https://api.mosaicwellness.in/appointment-scheduler/reports/";
let wp_domain = "https://manmatters.com/";
let ui_dr_dashboard_domain = `${window.location.origin}/`;
let man_matters_domain = "https://manmatters.com/";
let bodywise_domain = "https://bebodywise.com/";
let eventSchedulerApiDomain =
  "https://api.mosaicwellness.in/event-scheduler/core/";
const EC2_BASE_URL = "https://api.mosaicwellness.in/";
const DOCTRINA_ENDPOINT = "https://api.mosaicwellness.in/doctrina/";
const PORTAL_URL = "https://api.mosaicwellness.in/portal/";

export const prod = {
  url: {
    EDIT_PROFILE: api_domain + "editProfile",
    GET_ALL_RECOMMENDATION_RULES: api_domain + "getRecommendationRules",
    CREATE_RECOMMENDATION_RULE: api_domain + "createRecommendationRule",
    EDIT_RECOMMENDATION_RULE: api_domain + "editRecommendationRule",
    DELETE_RECOMMENDATION_RULE: api_domain + "deleteRecommendationRule",
    GET_RECOMMENDATION_PRODUCT: api_domain + "getRecommendationProduct",
    GET_RECOMMENDED_PRODUCTS: api_domain + "getRecommendedProduct",
    LOGIN: api_domain + "login",
    CS_LOGIN: api_domain + "cslogin",
    CREATE_PRESET: api_domain + "createPreset",
    DELETE_PRESET: api_domain + "deletePreset",
    GET_PRESCRIBED_PRODUCTS: api_domain + "getPrescribedProducts",
    EDIT_PRESET: api_domain + "editPreset",
    VERIFY_TOKEN: api_domain + "verifyToken",
    GET_DOCTOR_INFO_URL: api_domain + "getDoctorInfo",
    EXOTEL_C2C_URL: api_domain + "exotel",
    EXOTEL_CALL_DETAILS_URL: api_domain + "exotelCallDetails",
    UI_DR_DASHBOARD_URL: ui_dr_dashboard_domain,
    MAN_MATTERS_DOMAIN: man_matters_domain,
    BODYWISE_DOMAIN: bodywise_domain,
    APPOINTMENTS: report_api_domain + "appointments",
    ADMIN_DASHBOARDAPPOINTMENTS:
      "https://q01dkm14pk.execute-api.ap-south-1.amazonaws.com/prod/reports/" +
      "Adminappointments",
    ADMIN_DASHBOARD_PENDINGAPPOINTMENTS:
      report_api_domain + "Adminpendingappointments",
    MISSED_CALLS: report_api_domain + "missedCalls",
    PENDING_APPOINTMENTS:
      "https://q01dkm14pk.execute-api.ap-south-1.amazonaws.com/prod/reports/" +
      "pendingAppointments",
    PENDING_UPDATED_TODAY: report_api_domain + "pendingUpdatedToday",
    TODAY_REPORT_1: report_api_domain + "todayReport1",
    AD_FORM_DATA2: report_api_domain + "adminFormData",
    TODAY_REPORT_2: report_api_domain + "todayReport2",
    PENDING_REPORT_1: report_api_domain + "pendingReport1",
    PENDING_REPORT_2: report_api_domain + "pendingReport2",
    DOWNLOAD_ZIP: report_api_domain + "downloadZip",
    LIST_UPLOADED_IMAGES: report_api_domain + "listUploadedImages",
    PENDING_CUMULATIVE_REPORT_1: report_api_domain + "pendingCumulativeReport1",
    PENDING_CUMULATIVE_REPORT_2: report_api_domain + "pendingCumulativeReport2",
    DOCTOR_SCHEDULE: report_api_domain + "adminDoctorSchedule",
    DOCTOR_SUMMARY: report_api_domain + "adminDoctorSummary",
    DOCTOR_CONSULTATIONS: report_api_domain + "adminDoctorConsultations",
    DAILY_FU: report_api_domain + "adminDailyAppointmentFU",
    DAILY_F: report_api_domain + "adminDailyAppointmentF",
    DAILY_O: report_api_domain + "adminDailyAppointmentO",
    DOCTOR_FU: report_api_domain + "adminDoctorFU",
    DOCTOR_F: report_api_domain + "adminDoctorF",
    DOCTOR_O: report_api_domain + "adminDoctorO",
    WEEKLY_FU: report_api_domain + "adminWeeklyAppointmentFU",
    WEEKLY_F: report_api_domain + "adminWeeklyAppointmentF",
    WEEKLY_O: report_api_domain + "adminWeeklyAppointmentO",
    AD_PENDING_CUMULATIVE: report_api_domain + "adminPendingCumulative",
    AD_PENDING_COMPLETED_TODAY:
      report_api_domain + "adminPendingCompletedToday",
    WEEKLY_FF_FU: report_api_domain + "adminWeeklyFormfilledFU",
    WEEKLY_FF_F: report_api_domain + "adminWeeklyFormfilledF",
    WEEKLY_FF_O: report_api_domain + "adminWeeklyFormfilledO",
    DAILY_FF_FU: report_api_domain + "adminDailyFormfilledFU",
    DAILY_FF_F: report_api_domain + "adminDailyFormfilledF",
    DAILY_FF_O: report_api_domain + "adminDailyFormfilledO",
    AD_FORM_SUMMARY: report_api_domain + "adminFormsSummary",
    AD_COD_PREPAID: report_api_domain + "adminCOD&Prepaid",
    AD_WEEKLY_SUMMARY: report_api_domain + "adminWeeklySummary",
    AD_COD_VS_PREPAID: report_api_domain + "adminCODvsPrepaidAppointment",
    DOCTOR_HOURS: report_api_domain + "doctorHoursView",
    SAVE_DOC_HOURS: report_api_domain + "saveDoctorHours",
    DOCTOR_BREAK_TIME: report_api_domain + "doctorBreakTimeView",
    SAVE_DOC_BREAK_TIME: report_api_domain + "saveDoctorBreakTime",
    PENDING_ORDERS_STATISTICS: report_api_domain + "pendingOrdersStatistics",
    SOURCE_VIEW: report_api_domain + "sourceView",
    AD_FORM_DATA:
      "https://kw1ijgbtw6.execute-api.ap-south-1.amazonaws.com/prod/" +
      "adminFormData",
    AD_SEARCH: report_api_domain + "adminSearchAppointment",
    DOCTOR_OOC: report_api_domain + "doctorOutOfClinicView",
    DOCTOR_BULK_OOC: report_api_domain + "listAllDoctorIdsAndNames",
    DOCTOR_OOC_SAVE: report_api_domain + "saveDoctorOutOfClinic",
    DOCTOR_PERFORMANCE_VIEW: report_api_domain + "doctorPerformanceView",
    DOC_REASSIGN: scheduler_api_domain + "reassign",
    FORM_DATA_SEARCH: scheduler_api_domain + "adminFormDataSearch",
    GET_PDF_URL: api_domain + "getPdfUrl",
    GET_BODYWISE_PRODUCTS_URL: api_domain + "get_products",
    PRESCRIPTION_URL: api_domain + "prescription",
    GET_PRESET_URL: api_domain + "getPresetData",
    GET_PROFILE: api_domain + "getProfile",
    GET_TIMELINE: api_domain + "getTimeline",
    PRESCRIPTION_IMAGE_UPLOAD_URL: api_domain + "send_upload_link",
    PRESCRIPTION_GET_RX_IMAGES: api_domain + "get_rx_images",
    FORM_SUBMIT_EVENT_URL: api_domain + "formSubmit",
    ORDER_DETAILS_URL: api_domain + "orderDetails",
    UPDATE_VIDEOCALL_DURATION: api_domain + "updateVideocallDuration",
    HM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/hm_products.json",
    PM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/pm_products.json",
    HYM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/hym_products.json",
    IM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/im_products.json",
    WM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/wm_products.json",
    SM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/sm_products.json",
    SKM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/skm_products.json",
    NM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/nm_products.json",
    BM_PRODUCTS_URL:
      wp_domain + "wp-content/themes/betheme/json/bm_products.json",
    SEND_REPORT_URL: api_domain + "sendReport",
    GENERATE_REPORT_URL: api_domain + "generateReport",
    GENERATE_REPORT_URL__BW: api_domain + "v2/generateReport",
    GET_RX_TIMELINE_IMAGES: api_domain + "get_rx_timeline_images",
    UPDATE_STATUS_URL: api_domain + "changeStatus",
    CALL_STATUS_URL: scheduler_api_domain + "getCallStatus",
    UPDATE_TIME_SLOT: scheduler_api_domain + "updateTimeSlot",
    SEND_RESCHEDULR_MSG: scheduler_api_domain + "sendRescheduleToCustomer",
    RESCHEDULE_APPOINTMENT: scheduler_api_domain + "rescheduleAppointment",
    GET_HISTORY: api_domain + "eventInformation",
    UPDATE_LANGUAGE: api_domain + "updateLanguage",
    DOCTOR_LIST_URL: report_api_domain + "viewDoctors",
    DOCTOR_LIST_URL_ES: eventSchedulerApiDomain + "reports/viewDoctors",
    PREVIEW_CHANGES_ON_OOC: report_api_domain + "previewSaveDoctorOutOfClinic",
    DOCTOR_DASHBOARD_INFO: scheduler_api_domain + "getDoctorDashboardInfo",
    YOUR_PERFORMANCE: scheduler_api_domain + "yourPerformance",
    GET_DOCTOR: report_api_domain + "getDoctor",
    GET_ORDER_DATA: report_api_domain + "getOrderData",
    CHECK_PRESCRIPTION: report_api_domain + "checkPrescription",
    GET_CUSTOMER_DATA: report_api_domain + "getCustomerData",
    ADD_DOCTOR: report_api_domain + "addDoctor",
    UPDATE_DOCTOR: report_api_domain + "updateDoctor",
    HIT_EVERY_MIN_API: scheduler_api_domain + "hitEveryMin",
    DOCTOR_LOGIN_DETAILS_API: eventSchedulerApiDomain + "doctorLoginDetails",
    DOCTOR_ONLINE_STATUS: scheduler_api_domain + "getDoctorStatusData",
    PCI_ADMIN_DASHBOARD: report_api_domain + "pciAdminDashboard",
    GET_REVIEWS: report_api_domain + "getReviews",
    MARK_IRRELEVANCE: report_api_domain + "markIrrelevance",
    SAVE_CHECKIN_CHECKOUT: report_api_domain + "saveCheckInAndCheckOut",
    IS_CHECKEDIN: report_api_domain + "isCheckedIn",
    PCI_DOCTOR_DASHBOARD: report_api_domain + "pciDoctorDashboard",
    GET_DOCTOR_REVIEWS: report_api_domain + "getDoctorReviews",
    GET_FOOD_ITEMS: api_domain + "getFoodItems",
    GET_PRESCRIPTION_PRODUCTS: api_domain + "getPrescriptionProducts",
    CREATE_MEAL_PLAN: api_domain + "createMealPlan",
    SEND_MEAL_PLAN: api_domain + "sendMealPlan",
    BOOK_APPOINTMENT: scheduler_api_domain + "bookslot",
    SLOTS_AVAILABLE_2: scheduler_api_domain + "slots_available_2",
    SLOTS_AVAILABLE_3: scheduler_api_domain + "slots_available_3",
    SLOTS_FULL: scheduler_api_domain + "slots_full",
    PRODUCTS_VIEW: api_domain + "productsView",
    ORDER_OVERBOOKINGS: report_api_domain + "getOrderOverbookings",
    GET_SHORT_CALLS: report_api_domain + "getShortCalls",
    GET_USER_DETAILS_FOR_CS: eventSchedulerApiDomain + "getUserDetailsForCS",
    SAVE_MEAL_PLAN_DRAFT: api_domain + "saveMealPlanDraft",
    GET_MEAL_PLAN_DRAFT: api_domain + "getMealPlanDraft",
    REASSIGN_DOCTOR: api_domain + "reassignDoctor",
    CAPACITY_MANAGEMENT_HOURWISE:
      scheduler_api_domain + "capacity_management_hourwise",
    CAPACITY_MANAGEMENT_DOCTORWISE:
      scheduler_api_domain + "capacity_management_doctorwise",
    GET_QUICK_VIEW: api_domain + "quickView",
    UTILIZATION_MONTHWISE_HISTORY:
      scheduler_api_domain + "utilization_monthwise_history",
    UTILIZATION_MONTHWISE_REALTIME:
      scheduler_api_domain + "utilization_monthwise_realtime",
    UTILIZATION_DAYWISE_HISTORY:
      scheduler_api_domain + "utilization_daywise_history",
    UTILIZATION_DAYWISE_REALTIME:
      scheduler_api_domain + "utilization_daywise_realtime",
    GET_VIDEO_CALL_TOKEN: scheduler_api_domain + "/getVideoCallToken",
    SEND_FCM_NOTIFICATION: api_domain + "/sendFcmNotification",
    GET_AGORA_MEMBERS:
      "https://q01dkm14pk.execute-api.ap-south-1.amazonaws.com/prod/" +
      "getAgoraMembers",
    SEND_AGORA_REQUEST: scheduler_api_domain + "sendAgoraRequest",
    SEND_AGORA_GET_REQUEST: scheduler_api_domain + "sendAgoraGetRequest",
    SAVE_VC_RECORDING: api_domain + "saveVCRecording",
    DOWNLOAD_VC_RECORDING: report_api_domain + "downloadVCRecording",
    TRACK_RX: report_api_domain + "trackRx",
    GET_VC_RECORDINGS: report_api_domain + "getVCRecordings",
    GET_VC_TIMING: report_api_domain + "getVCTiming",
    EXPORT_DA: report_api_domain + "exportDA",
    GET_DOCTORS_BY_CATEGORY: report_api_domain + "getDoctorsByCategory",
    GET_DOCTORS_BY_CATEGORY_ES:
      eventSchedulerApiDomain + "reports/getDoctorsByCategory",
    SEARCH_APPOINTMENT: report_api_domain + "searchAppointment",
    CHECK_VC_REJECTED: scheduler_api_domain + "checkVCRejected",
    GET_APPOINTMENT_MODE: api_domain + "getAppointmentMode",
    GET_DIET_DETAILS: api_domain + "getDietDetails",
    GET_APPOINTMENT_DETAILS: scheduler_api_domain + "getAppointmentDetails",
    SEND_WHATSAPP_LINK: scheduler_api_domain + "sendVideoLink",
    SOCKET_CURRENT_APPOINTMENT: EC2_BASE_URL + "currentAppointment",
    SOCKET_TRIGGER_VIDEO_CALL: EC2_BASE_URL + "triggerVideoCall",
    SOCKET_TRIGGER_SHORT_CALL: EC2_BASE_URL + "shortCallAlert",
    SOCKET_DOCTOR_STATUS_CHANGE: EC2_BASE_URL + "doctorStatusChange",
    DA_QUESTION_JSON_MM:
      "https://forms-service-json.s3.ap-south-1.amazonaws.com/app_da_questions_prod.json",
    DA_QUESTION_JSON_BW:
      "https://forms-service-json.s3.ap-south-1.amazonaws.com/BW/app_da_questions_prod.json",
    UPDATE_COOLOFF: eventSchedulerApiDomain + "cooloff",
    COUNT_OVERVIEW: eventSchedulerApiDomain + "reporting/countOverview",
    ASSIGNMENT_METRICS:
      eventSchedulerApiDomain + "reporting/getAssignmentMetrics",
    APPOINTMENT_AUDIO_LOGS:
      eventSchedulerApiDomain + "reporting/getAllAppointmentAudioLogs",
    GET_DOCTOR_LAST_UPDATES:
      eventSchedulerApiDomain + "reporting/getDoctorLastUpdates",
    DOCTOR_VIEW_AUDIT_LOGS:
      eventSchedulerApiDomain + "reporting/getDoctorViewAuditLogs",
    GET_AUDIT_LOGS: eventSchedulerApiDomain + "reporting/getAuditLogs",
    GET_DOCHUB_USER_LOGS:
      eventSchedulerApiDomain + "reporting/getDochubUserLogs",
    APPOINTMENT_COUNTS: EC2_BASE_URL + "event-scheduler/core/reporting/summary",
    PENDING_APPOINTMENTS_FOR_STANDBY:
      eventSchedulerApiDomain + "reporting/pendingAppointmentsForStandby",
    CATEGORY_SLOTS: eventSchedulerApiDomain + "category/slots",
    DOCTOR_SLOTS: eventSchedulerApiDomain + "doctor/slots",
    RESCHEDULE_APPOINTMENT_V2:
      eventSchedulerApiDomain + "rescheduleAppointment",
    BOOK_APPOINTMENT_V2: eventSchedulerApiDomain + "category/book",
    CS_BOOK_APPOINTENT: eventSchedulerApiDomain + "category/bookSlotForCs",
    ADD_TO_COOLOFF: eventSchedulerApiDomain + "reports/checkoutDoctor",
    CHECK_DOCTOR_IN: eventSchedulerApiDomain + "manualDoctorCheckIn",
    GET_ALL_DOCTOR_ACTIVITY_STATUS_LOGS:
      eventSchedulerApiDomain + "reporting/getAllDoctorActivityStatus",
    INSERT_ANNOTATION: DOCTRINA_ENDPOINT + "insertAnnotation",
    GET_IMAGE_URL: DOCTRINA_ENDPOINT + "fetchImageUrl",
    GET_IMAGE_ID: DOCTRINA_ENDPOINT + "fetchImageId",
    FECTH_IMAGE_ANNOTATIONS: DOCTRINA_ENDPOINT + "fetchImageAnnotations",
    VERIFY_IMAGE_ANNOTATIONS: DOCTRINA_ENDPOINT + "verifyAnnotations",
    GET_ANNOTATED_DOCTORS: DOCTRINA_ENDPOINT + "fetchAnnotationDoctors",
    GET_SUGGESTIONS_LIST: eventSchedulerApiDomain + "observationPresets",
    GET_CATEGORY_LIST: DOCTRINA_ENDPOINT + "getCategories",
    SYSTEM_CHECK_URL: eventSchedulerApiDomain + "reporting/systemHealth",
    NEW_DOCTOR_HOURS: eventSchedulerApiDomain + "reporting/fetchDoctorHours",
    DOCTOR_BRAND_MAPPING:
      eventSchedulerApiDomain + "reporting/fetchBrandMapping",
    DOCTOR_CATEGORY_MAPPING:
      eventSchedulerApiDomain + "reporting/fetchCategoryMapping",
    DOCTOR_LANGUAGE_MAPPING:
      eventSchedulerApiDomain + "reporting/fetchLanguageMapping",
    UPDATE_DOCTOR_HOURS:
      eventSchedulerApiDomain + "reporting/updateDoctorHours",
    UPDATE_DOCTOR_LANGUAGE:
      eventSchedulerApiDomain + "reporting/updateLanguageDetails",
    UPDATE_DOCTOR_CATEGORY:
      eventSchedulerApiDomain + "reporting/updateCategoryDetails",
    UPDATE_DOCTOR_BRAND:
      eventSchedulerApiDomain + "reporting/updateBrandDetails",
    ALL_DOCTORS_OVERVIEW_DETAILS:
      eventSchedulerApiDomain + "reporting/allDoctorsOverview",
    STANDBY_INITIATE_CALL: eventSchedulerApiDomain + "standbyInitiateCall",
    UPDATE_STEP_AWAY: eventSchedulerApiDomain + "updateStepAway",
    SEND_RX_STATUS_LIST: eventSchedulerApiDomain + "sendRxStatusList",
    INSERT_ANNOTATION: DOCTRINA_ENDPOINT + "insertAnnotation",
    GET_IMAGE_URL: DOCTRINA_ENDPOINT + "fetchImageUrl",
    GET_IMAGE_ID: DOCTRINA_ENDPOINT + "fetchImageId",
    FECTH_IMAGE_ANNOTATIONS: DOCTRINA_ENDPOINT + "fetchImageAnnotations",
    GET_SUGGESTIONS_LIST: eventSchedulerApiDomain + "observationPresets",
    GET_CATEGORY_LIST: DOCTRINA_ENDPOINT + "getCategories",
    DOC_SEND_OTP: PORTAL_URL + "auth/send-dochub-otp",
    DOC_VERIFY_OTP: PORTAL_URL + "auth/verify-dochub-otp",
    LIST_DOCTORS: eventSchedulerApiDomain + "doctor/list",
    ADD_DOCTOR_V2: eventSchedulerApiDomain + "doctor/add",
    UPDATE_DOCTOR_V2: eventSchedulerApiDomain + "doctor/update",
    DOC_VERIFY_TOKEN: PORTAL_URL + "auth/verify-login"
  },
  properties: {
    MIXPANEL_TOKEN: "ffc65a2dbd4c9836afc9616277e11161",
    GTM_ID: "GTM-N2GP4BH"
  },
  AUTH: {
    LOGIN: PORTAL_URL + "iam/login",
    REFRESH: PORTAL_URL + "iam/refresh",
    EXCHANGE_TOKEN: PORTAL_URL + "iam/exchangeToken",
    LOGOUT: PORTAL_URL + "iam/logout",
    VERIFY: PORTAL_URL + "iam/verify",
    SPOOF: PORTAL_URL + "iam/spoof",
    CALLBACK_URL: "/login-callback"
  }
};
