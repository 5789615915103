import { store } from "../reducers/Persist";
const debug = process.env.REACT_APP_ENV !== "production";

export const addToGTM = dataLayerDetails => {
  try {
    console.log("in heree",JSON.stringify(dataLayerDetails))
    if (window?.dataLayer) {
      console.log("hereee")
      const loginDetails = store?.getState()?.userReducer || {};
      const eventData = {
        login: loginDetails,
        ...dataLayerDetails
      };
      console.log("eventData",eventData)
      if (debug) console.log("DATALAYER PUSH |", eventData);
      window.dataLayer.push(eventData);
      console.log("data",window.dataLayer)
    } else {
      console.warn("window.dataLayer not found!!!");
    }
  } catch (err) {
    console.error("ERROR IN addToGTM", err);
  }
};
