import styled from "styled-components";

export const AudioAppointmentText = styled.p`
  text-align: center;
  color: #959595;
  font-size: 28px;
  line-height: 32px;
  margin-top: 32px;
`;

export const AudioPatientFirstAppointmentText = styled.p`
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  margin-top: 32px;
  border: 0px solid black;
  background-color: #ffff00;
`;

export const AudioAppointmentTitleText = styled.p`
  text-align: center;
  color: #959595;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 8px;
  margin-top: 18px;
`;

export const AudioAppointmentCategoryBlock = styled.div`
  color: "#718096",
  borderColor: "#718096",
  border: "1px solid #718096",
  borderRadius: "2px",
  paddingLeft: "4px",
  paddingRight: "4px",
  fontWeight: "bold"
`;
