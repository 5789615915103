import { useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";
import { currentAppointmentSocketConfig } from "../../../constants/socketList";
import { DEFAULT_DOCTOR_ID } from "../../core/NewDoctorDashboard/CurrentAppointment/withSocketHOC";

const EVENT_NAME = {
  CURRENT_APPOINTMENT: "currentAppointment",
  RESET_CURRENT_APPOINTMENT_INDICATOR: "currentAppointmentReset",
  POP_UP_CALL_MODAL: "popUpCallModal",
  CLOSE_POP_UP_CALL_MODAL: "closePopUpCallModal"
};

const { socketUrl, socketPath } = currentAppointmentSocketConfig;
export const useCurrentAppointmentSocket = (
  userId,
  doctorJwtToken,
  doctorId,
  triggerCallNotificationPopup
) => {
  /**
   * @description The socket connection to the server
   */
  const socket = useMemo(
    () =>
      io(socketUrl, {
        auth: {
          user_id: userId,
          token: doctorJwtToken,
          doctorId
        },
        path: socketPath
        // transports: ["polling"]
      }),
    [doctorId, doctorJwtToken, userId]
  );
  /**
   * Socket statuses define whether the socket is connected and if its loading
   */
  const [socketStatus, setSocketStatus] = useState({
    isSocketLoading: true,
    isSocketConnected: false,
    isSocketError: false,
    socketErrorMessage: ""
  });

  /**
   * Data the we receive from the backend
   */
  const [currentAppointmentData, setCurrentAppointmentData] = useState();
  useEffect(() => {
    socket.on("connect", () => {
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: false,
        isSocketConnected: true,
        isSocketError: false,
        socketErrorMessage: ""
      });
    });
    socket.on("disconnect", () => {
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: true,
        isSocketConnected: false,
        isSocketError: false,
        socketErrorMessage: ""
      });
    });
    socket.on(EVENT_NAME.CURRENT_APPOINTMENT, response => {
      try {
        const { data } = response;
        const {
          data: {
            appointmentInfo: { doctorId: _doctorId }
          }
        } = data;

        if (!_doctorId || parseInt(_doctorId) !== parseInt(doctorId)) {
          console.warn(
            "Received data from socket with wrong doctor id: ",
            data?.doctorId ?? "No doctor id"
          );
          return;
        }
        setCurrentAppointmentData(data);
      } catch (e) {
        console.error(e);
        setSocketStatus({
          ...socketStatus,
          isSocketLoading: true,
          isSocketConnected: false,
          isSocketError: true,
          socketErrorMessage: e
        });
        setCurrentAppointmentData(null);
      }
    });

    socket.on(EVENT_NAME.POP_UP_CALL_MODAL, response => {
      const _doctorId = response?.doctorId ?? DEFAULT_DOCTOR_ID;
      try {
        if (parseInt(_doctorId) !== parseInt(doctorId)) {
          console.warn(
            "Received data from socket with wrong doctor id: ",
            _doctorId ?? "No doctor id"
          );
          return;
        }
        triggerCallNotificationPopup(true, response);
      } catch (e) {
        console.error(e);
        setSocketStatus({
          ...socketStatus,
          isSocketLoading: true,
          isSocketConnected: false,
          isSocketError: true,
          socketErrorMessage: e
        });
      }
    });

    socket.on(EVENT_NAME.CLOSE_POP_UP_CALL_MODAL, response => {
      const _doctorId = response?.doctorId ?? DEFAULT_DOCTOR_ID;
      try {
        if (parseInt(_doctorId) !== parseInt(doctorId)) {
          console.warn(
            "Received data from socket with wrong doctor id: ",
            _doctorId ?? "No doctor id"
          );
          return;
        }
        triggerCallNotificationPopup(false, response);
      } catch (e) {
        console.error(e);
        setSocketStatus({
          ...socketStatus,
          isSocketLoading: true,
          isSocketConnected: false,
          isSocketError: true,
          socketErrorMessage: e
        });
      }
    });

    socket.on(EVENT_NAME.RESET_CURRENT_APPOINTMENT_INDICATOR, _doctorId => {
      try {
        if (parseInt(_doctorId) !== parseInt(doctorId)) {
          console.warn(
            "Received data from socket with wrong doctor id: ",
            _doctorId ?? "No doctor id"
          );
          return;
        }
        setCurrentAppointmentData(null);
      } catch (e) {
        console.error(e);
        setSocketStatus({
          ...socketStatus,
          isSocketLoading: true,
          isSocketConnected: false,
          isSocketError: true,
          socketErrorMessage: e
        });
      }
    });

    socket.on("connect_error", error => {
      setSocketStatus({
        ...socketStatus,
        isSocketConnected: false,
        isSocketError: true,
        socketErrorMessage: error.message,
        isSocketLoading: false
      });
      setCurrentAppointmentData(null);
    });
    return () => {
      socket.close();
      setSocketStatus({
        ...socketStatus,
        isSocketLoading: true,
        isSocketConnected: false,
        isSocketError: false,
        socketErrorMessage: ""
      });
      setCurrentAppointmentData(null);
    };
  }, [socket]);

  return { socketStatus, currentAppointmentData };
};
